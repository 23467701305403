import type { Plugin } from 'vue'
import * as Sentry from '@sentry/vue'

export default function(): Plugin {
    return {
        install(app) {
            if (import.meta.env.PROD) {
                Sentry.init({
                    app,
                    dsn: 'https://5a5b302cdc4197bf0a95253499f0a627@o4507173371707392.ingest.us.sentry.io/4507173375049728',
                    integrations: [
                        Sentry.replayIntegration(),
                    ],
                    logErrors: true,
                    trackComponents: true,
                    // Session Replay
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                })
            }
        },
    }
}
