import { definePlugin } from "hybridly";
import { useProperty } from "hybridly/vue";
import { toast } from "vue-sonner";

export interface ToastPluginOptions {}

export default function ToastPlugin(options?: ToastPluginOptions) {
    return definePlugin({
        name: "hybridly:toast",
        after: () => {
            const flash = useProperty(
                'flash',
            ).value;

            if (!flash) {
                return;
            }

            if (flash.success) {
                toast.success(flash.success);
            }
            if (flash.warning) {
                toast.warning(flash.warning);
            }
            if (flash.info) {
                toast.info(flash.info);
            }
            if (flash.error) {
                toast.error(flash.error);
            }
        },
    });
}
